/* limited_container { */
  /* background: url("./assets/overlay.png") no-repeat, */
    /* Overlay image url("./assets/image.png") no-repeat; Background image */
  /* background-size: cover, cover; Cover both images to fill the container */
/* } */

.backg_right {
  background-position: right;
}

.backg_left {
  background-position: left;
}