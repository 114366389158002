@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");
:root {
  --cairo_font: "Cairo", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafcfd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.swiper {
  /* width: 100%; */
  /* height: auto; */
  /* aspect-ratio: 16 / 9;  */
  position: relative;
}
/*
.swiper-slide {
  display: flex; 
  align-items: center; 
  justify-content: center; 
  position: relative; 
} */

/* Customize pagination as needed */
.swiper-pagination {
  bottom: 80px !important;
}

.swiper-pagination-bullet {
  background-color: rgb(255, 255, 255, 40%) !important;
  width: 12px !important;
  height: 12px !important;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: #fd5674 !important;
}

.css-19kzrtu {
  padding-bottom: 0 !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #fd5674;
}

::-webkit-scrollbar-track {
  background: #444;
}

.notyf__toast {
  font-size: 20px;
  padding: 10px;
  border-radius: 10px;
  width: 700px;
}

body {
  padding-top: 80px
}

input::placeholder {
  font-size: 15px !important;
}

.swiper_slide {
  box-shadow: none !important;
}
.hero_slider_body__vh8q8::after {
  background-color: rgba(0, 0, 0, .150) !important;
}

.swiper-pagination {
  bottom: 10px !important;
}

.swiper-pagination-bullet {
  border: 1px solid #777 !important;
}