/* From Uiverse.io by Fernando-sv */
.loaderFavu {
  border: 3px solid red;
  border-left-color: transparent;
  border-radius: 50%;
}

.loaderFavu {
  border: 3px solid red;
  border-left-color: transparent;
  width: 30px;
  height: 30px;
}

.loaderFavu {
  border: 43x solid red;
  border-left-color: transparent;
  width: 20px;
  height: 20px;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}