.toggle_navgationBarControllingSideBar {
  display: none;
}

@media (max-width: 750px) {
  .navigate_bar_links {
    top: 0px;
  }
  .navBarLinksLinks {
    display: none;
  }
  .toggle_navgationBarControllingSideBar {
    display: flex;
  }
}

@media (max-width: 500px) {
  .navigate_bar_links {
    padding-inline: 20px;
  }
  .notfcationContent {
    width: 300px !important;
    /* left: 50% !important;
    transform: translate(-50%); */
  }
}

@media (max-width: 420px) {
  .notificationIcon_nav {
    width: 150px !important;
  }
}