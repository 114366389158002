.slider_body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);
  overflow: hidden;
  /* position: relative; */
}

.hero_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* position: relative; */
}

.hero_devider {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.slider_body::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 99%;
  background-color: rgba(29, 29, 29, 0.47);
}
.devider {
  height: 40px;
  width: 1px;
  background-color: rgba(186, 192, 202, 20%);
}

.search_bar {
  position: absolute;
  bottom: 75px;
  z-index: 20;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 1rem 0.5rem;
  height: 100px;
  border-radius: 80px;
}
.swiper_slide {
  border: none !important;
  outline: none !important;
}
.cost {
  border-radius: 60px;
}

.rtl {
  direction: rtl;
}
