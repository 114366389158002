.page_banner {
  height: 275px;
  margin-top: 13px;
  width: 100%;
  background: url("./assets/image.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.page_banner::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgb(159, 6, 34, 70%);
}

.search_bar {
  position: absolute;
  bottom: -50px;
  z-index: 20;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 1rem 0.5rem;
  height: 100px;
  border-radius: 80px;
}
